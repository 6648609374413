const ExperienceSection = () => {
  return (
    <div className='section experienceSection'>
        <aside className='csAside exAside'>
            <p className='fadeItemText'>EST. 2012</p>
            <h2>Experience</h2>
        </aside>
        <div className='exInformation'>
            <p className='exYears'>10+<br/><span>Years</span></p>
            <p className='exInfo'><span className='crootiveName'>C<span className='setGreen'>root</span>ive</span> started with an idea to make everyday tasks Easier, Faster and Better. At <span className='crootiveName'>C<span className='setGreen'>root</span>ive</span> the first thing we focus on is simplicity and user friendliness. We can help you create the software you always wanted for your company.</p>
        </div>
    </div>
  )
}

export default ExperienceSection