
const ImageHero = () => {
  return (
    <div
      className="heroImage"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 800 800"
        opacity="1"
      >
        <defs>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="gggyrate-grad"
          >
            <stop
              stopColor="hsl(182, 54%, 36%)"
              stopOpacity="1"
              offset="0%"
            ></stop>
            <stop
              stopColor="hsl(192, 10%, 10%)"
              stopOpacity="1"
              offset="100%"
            ></stop>
          </linearGradient>
        </defs>
        <g strokeWidth="2.5" stroke="url(#gggyrate-grad)" fill="none">
          <path
            d="M372.9030956662592 9.189042608889565C389.67067895225523 -0.4899825200015471 410.329382082901 -0.4899825200015471 427.09696536889703 9.19175230237471L724.9031181588321 181.1299330144484C741.6707014448282 190.8089581433395 752.000053010151 208.7010642256654 752.000053010151 228.06182417693287V571.9381856010801C752.000053010151 591.2989455523475 741.6707014448282 609.1910516346735 724.9031181588321 618.8727864570496L427.09696536889703 790.8109671691234C410.329382082901 800.4899922980144 389.67067895225523 800.4899922980144 372.9030956662592 790.8082574756381L75.09694287632414 618.8700767635646C58.32935959032807 609.1910516346735 48.000008025005286 591.2989455523475 48.000008025005286 571.9381856010801V228.06182417693287C48.000008025005286 208.7010642256654 58.32935959032807 190.8089581433395 75.09694287632414 181.1272233209633L372.9030956662592 9.189042608889565Z "
            transform="rotate(64, 400, 400)"
            opacity="0.05"
          ></path>
          <path
            d="M373.749874880363 21.401898855639388C389.9934711886717 12.025343262026098 410.00658984648453 12.025343262026098 426.25018615479325 21.404523871203082L714.7498966700429 187.96963643602442C730.9934929783516 197.34619202963773 741.0000523072581 214.67916979689096 741.0000523072581 233.4349059996813V566.565131129324C741.0000523072581 585.3208673321144 730.9934929783516 602.6538450993676 714.7498966700429 612.0330257085444L426.25018615479325 778.5981382733657C410.00658984648453 787.9746938669791 389.9934711886717 787.9746938669791 373.749874880363 778.5955132578022L85.25016436511333 612.0304006929808C69.00656805680461 602.6538450993676 59.00000872789815 585.3208673321144 59.00000872789815 566.565131129324V233.4349059996813C59.00000872789815 214.67916979689096 69.00656805680461 197.34619202963773 85.25016436511333 187.96701142046078L373.749874880363 21.401898855639388Z "
            transform="rotate(62, 400, 400)"
            opacity="0.08"
          ></path>
          <path
            d="M374.59665409446666 33.61472458481103C390.316263425088 24.540638526475618 409.68379761006827 24.540638526475618 425.4034069406896 33.61726492245333L704.5966751812537 194.80930934002242C720.3162845118751 203.88339539835783 730.0000516043651 220.6572448505383 730.0000516043651 238.80795730485158V561.1920461399895C730.0000516043651 579.3427585943028 720.3162845118751 596.1166080464833 704.5966751812537 605.1932344424611L425.4034069406896 766.3852788600301C409.68379761006827 775.4593649183655 390.316263425088 775.4593649183655 374.59665409446666 766.3827385223879L95.40338585390253 605.1906941048188C79.68377652328115 596.1166080464833 70.00000943079107 579.3427585943028 70.00000943079107 561.1920461399895V238.80795730485158C70.00000943079107 220.6572448505383 79.68377652328115 203.88339539835783 95.40338585390253 194.80676900238012L374.59665409446666 33.61472458481103Z "
            transform="rotate(60, 400, 400)"
            opacity="0.11"
          ></path>
          <path
            d="M375.4434333085703 45.82755031398267C390.63905566150436 37.05593379092511 409.3610053736519 37.05593379092511 424.55662772658593 45.83000597370358L694.4434536924646 201.64898224402032C709.6390760453985 210.42059876707793 719.0000509014724 226.63531990418574 719.0000509014724 244.18100861002188V555.8189611506554C719.0000509014724 573.3646498564915 709.6390760453985 589.5793709935994 694.4434536924646 598.3534431763777L424.55662772658593 754.1724194466944C409.3610053736519 762.944035969752 390.63905566150436 762.944035969752 375.4434333085703 754.1699637869737L105.55660734269173 598.3509875166569C90.36098498975775 589.5793709935994 81.00001013368399 573.3646498564915 81.00001013368399 555.8189611506554V244.18100861002188C81.00001013368399 226.63531990418574 90.36098498975775 210.42059876707793 105.55660734269173 201.64652658429952L375.4434333085703 45.82755031398267Z "
            transform="rotate(58, 400, 400)"
            opacity="0.14"
          ></path>
          <path
            d="M376.2902125226741 58.04040656073249C390.96184789792073 49.571259572952755 409.0382131372355 49.571259572952755 423.70984851248215 58.04277754253201L684.2902322036754 208.48868566559645C698.9618675789219 216.95783265337616 708.0000501985794 232.6134254754113 708.0000501985794 249.5540904327703V550.4459066788993C708.0000501985794 567.3865716362582 698.9618675789219 583.0421644582934 684.2902322036754 591.5136824278725L423.70984851248215 741.959590550937C409.0382131372355 750.4287375387166 390.96184789792073 750.4287375387166 376.2902125226741 741.9572195691376L115.70982883148088 591.5113114460731C101.0381934562343 583.0421644582934 92.00001083657685 567.3865716362582 92.00001083657685 550.4459066788993V249.5540904327703C92.00001083657685 232.6134254754113 101.0381934562343 216.95783265337616 115.70982883148088 208.486314683797L376.2902125226741 58.04040656073249Z "
            transform="rotate(56, 400, 400)"
            opacity="0.17"
          ></path>
          <path
            d="M377.1369917367779 70.25326280748226C391.284640134337 62.0865853549804 408.71542090081925 62.0865853549804 422.8630692983784 70.25554911136038L674.1370107148862 215.32838908717247C688.2846591124454 223.49506653967438 697.0000494956864 238.59153104663687 697.0000494956864 254.92717225551877V545.0728522071431C697.0000494956864 561.408493416025 688.2846591124454 576.5049579229874 674.1370107148862 584.6739216793674L422.8630692983784 729.7467616551794C408.71542090081925 737.9134391076814 391.284640134337 737.9134391076814 377.1369917367779 729.7444753513015L125.86305032027008 584.6716353754892C111.7154019227109 576.5049579229874 103.00001153946977 561.408493416025 103.00001153946977 545.0728522071431V254.92717225551877C103.00001153946977 238.59153104663687 111.7154019227109 223.49506653967438 125.86305032027008 215.32610278329446L377.1369917367779 70.25326280748226Z "
            transform="rotate(54, 400, 400)"
            opacity="0.20"
          ></path>
          <path
            d="M377.98377095088154 82.46608853665396C391.6074323707534 74.60188061942989 408.3926286644029 74.60188061942989 422.0162900842747 82.46829016261063L663.983789226097 222.16806199117048C677.6074506459688 230.03226990839448 686.0000487927936 244.56960610028432 686.0000487927936 260.3002235606891V539.6997672178088C686.0000487927936 555.4303846782136 677.6074506459688 569.9677208701033 663.983789226097 577.834130413284L422.0162900842747 717.5339022418439C408.3926286644029 725.3981101590679 391.6074323707534 725.3981101590679 377.98377095088154 717.5317006158872L136.01627180905928 577.8319287873273C122.39261038918744 569.9677208701033 114.00001224236269 555.4303846782136 114.00001224236269 539.6997672178088V260.3002235606891C114.00001224236269 244.56960610028432 122.39261038918744 230.03226990839448 136.01627180905928 222.16586036521386L377.98377095088154 82.46608853665396Z "
            transform="rotate(52, 400, 400)"
            opacity="0.23"
          ></path>
          <path
            d="M378.8305501649852 94.67894478340372C391.93022460716975 87.11720640145754 408.0698364279865 87.11720640145754 421.16951087017105 94.681061731439L653.8305677373079 229.0077654127465C666.9302421794923 236.5695037946927 675.0000480899007 250.54771167150977 675.0000480899007 265.67330538343754V534.3267127460525C675.0000480899007 549.4523064579803 666.9302421794923 563.4305143347974 653.8305677373079 570.9943696647788L421.16951087017105 705.3210733460862C408.0698364279865 712.8828117280324 391.93022460716975 712.8828117280324 378.8305501649852 705.3189563980511L146.16949329784848 570.9922527167435C133.06981885566398 563.4305143347974 125.00001294525555 549.4523064579803 125.00001294525555 534.3267127460525V265.67330538343754C125.00001294525555 250.54771167150977 133.06981885566398 236.5695037946927 146.16949329784848 229.00564846471133L378.8305501649852 94.67894478340372Z "
            transform="rotate(50, 400, 400)"
            opacity="0.26"
          ></path>
          <path
            d="M379.677329379089 106.89177051257536C392.253016843586 99.63250166590706 407.74704419157024 99.63250166590706 420.3227316560673 106.89380278268925L643.6773462485187 235.8474383167445C656.2530337130157 243.1067071634128 664.0000473870078 256.5257867251572 664.0000473870078 271.04635668860783V528.9536277567183C664.0000473870078 543.4741977201688 656.2530337130157 556.8932772819132 643.6773462485187 564.1545783986953L420.3227316560673 693.1082139327507C407.74704419157024 700.367482779419 392.253016843586 700.367482779419 379.677329379089 693.1061816626369L156.32271478663762 564.1525461285817C143.74702732214058 556.8932772819132 136.00001364814847 543.4741977201688 136.00001364814847 528.9536277567183V271.04635668860783C136.00001364814847 256.5257867251572 143.74702732214058 243.1067071634128 156.32271478663762 235.84540604663067L379.677329379089 106.89177051257536Z "
            transform="rotate(48, 400, 400)"
            opacity="0.30"
          ></path>
          <path
            d="M380.5240933344036 119.10462675932519C392.5757938212133 112.14782744793467 407.4242366963648 112.14782744793467 419.47593718317444 119.10657435151762L633.5241095009403 242.68714173832052C645.5758099877501 249.6439410497111 653.0000314253258 262.5038922963828 653.0000314253258 276.41943851135625V523.5805732849622C653.0000314253258 537.4961194999356 645.5758099877501 550.3560707466073 633.5241095009403 557.3148176501902L419.47593718317444 680.8953850369932C407.4242366963648 687.8521843483836 392.5757938212133 687.8521843483836 380.5240933344036 680.8934374448007L166.47592101663776 557.3128700579978C154.42422052982806 550.3560707466073 146.99999909225232 537.4961194999356 146.99999909225232 523.5805732849622V276.41943851135625C146.99999909225232 262.5038922963828 154.42422052982806 249.6439410497111 166.47592101663776 242.6851941461282L380.5240933344036 119.10462675932519Z "
            transform="rotate(46, 400, 400)"
            opacity="0.33"
          ></path>
          <path
            d="M381.37087254850735 131.31745248849683C392.89858605762964 124.6631227123842 407.1014444599484 124.6631227123842 418.6291579690708 131.31931540276787L623.3708880121512 249.52681464231853C634.8986015212735 256.18114441843113 642.0000307224329 268.4819673500302 642.0000307224329 281.79248981652654V518.2074882956279C642.0000307224329 531.5180107621243 634.8986015212735 543.8188336937233 623.3708880121512 550.475026384107L418.6291579690708 668.6825256236575C407.1014444599484 675.3368553997702 392.89858605762964 675.3368553997702 381.37087254850735 668.6806627093865L176.62914250542696 550.473163469836C165.10142899630466 543.8188336937233 157.99999979514524 531.5180107621243 157.99999979514524 518.2074882956279V281.79248981652654C157.99999979514524 268.4819673500302 165.10142899630466 256.18114441843113 176.62914250542696 249.52495172804754L381.37087254850735 131.31745248849683Z "
            transform="rotate(44, 400, 400)"
            opacity="0.36"
          ></path>
          <path
            d="M382.21763650382195 143.53030873524665C393.2213630352569 137.17844849441184 406.7786369647431 137.17844849441184 417.78236349617805 143.53208697159624L613.2176512645729 256.3665180638946C624.2213777960078 262.7183783047294 631.0000147607509 274.46007292125574 631.0000147607509 287.165571639275V512.8344338238717C631.0000147607509 525.539932541891 624.2213777960078 537.2816271584173 613.2176512645729 543.6352656356016L417.78236349617805 656.4696967279C406.7786369647431 662.8215569687349 393.2213630352569 662.8215569687349 382.21763650382195 656.4679184915504L186.7823487354271 543.6334873992521C175.77862220399214 537.2816271584173 168.99998523924904 525.539932541891 168.99998523924904 512.8344338238717V287.165571639275C168.99998523924904 274.46007292125574 175.77862220399214 262.7183783047294 186.7823487354271 256.364739827545L382.21763650382195 143.53030873524665Z "
            transform="rotate(42, 400, 400)"
            opacity="0.39"
          ></path>
          <path
            d="M383.0644157179257 155.74316498199642C393.5441552716733 149.69377427643946 406.4558447283267 149.69377427643946 416.9355842820743 155.74485854042462L603.0644297757838 263.2062214854707C613.5441693295313 269.2556121910276 620.000014057858 280.43817849248126 620.000014057858 292.53865346202343V507.4613793521155C620.000014057858 519.5618543216576 613.5441693295313 530.7444206231113 603.0644297757838 536.7955048870965L416.9355842820743 644.2568678321425C406.4558447283267 650.3062585376995 393.5441552716733 650.3062585376995 383.0644157179257 644.2551742737144L196.93557022421624 536.7938113286682C186.45583067046869 530.7444206231113 179.99998594214196 519.5618543216576 179.99998594214196 507.4613793521155V292.53865346202343C179.99998594214196 280.43817849248126 186.45583067046869 269.2556121910276 196.93557022421624 263.2045279270425L383.0644157179257 155.74316498199642Z "
            transform="rotate(40, 400, 400)"
            opacity="0.42"
          ></path>
          <path
            d="M383.9111949320294 167.955975452379C393.8669475080896 162.2090542820999 406.1330524919104 162.2090542820999 416.0888050679706 167.9575843328858L592.9112082869946 270.04587913067957C602.8669608630547 275.7928003009587 609.0000133549652 286.41623828733964 609.0000133549652 297.9116895084047V502.08827910399214C609.0000133549652 513.5837303250572 602.8669608630547 524.2071683114382 592.9112082869946 529.9556983622241L416.0888050679706 632.0439931600177C406.1330524919104 637.7909143302968 393.8669475080896 637.7909143302968 383.9111949320294 632.042384279511L207.08879171300543 529.9540894817173C197.13303913694529 524.2071683114382 190.99998664503488 513.5837303250572 190.99998664503488 502.08827910399214V297.9116895084047C190.99998664503488 286.41623828733964 197.13303913694529 275.7928003009587 207.08879171300543 270.04427025017276L383.9111949320294 167.955975452379Z "
            transform="rotate(38, 400, 400)"
            opacity="0.45"
          ></path>
          <path
            d="M384.75797414613317 180.16884695791788C394.1897397445059 174.72439532291662 405.8102602554941 174.72439532291662 415.24202585386683 180.17037116050324L582.7579867982054 276.8855978110447C592.1897523965781 282.3300494460459 598.0000126520722 292.39435911735427 598.0000126520722 303.2847865899422V496.715239891025C598.0000126520722 507.60566736361295 592.1897523965781 517.6699770349212 582.7579867982054 523.1159528725079L415.24202585386683 619.8311795230493C405.8102602554941 625.2756311580506 394.1897397445059 625.2756311580506 384.75797414613317 619.829655320464L217.24201320179463 523.1144286699225C207.81024760342183 517.6699770349212 201.99998734792774 507.60566736361295 201.99998734792774 496.715239891025V303.2847865899422C201.99998734792774 292.39435911735427 207.81024760342183 282.3300494460459 217.24201320179463 276.88407360845935L384.75797414613317 180.16884695791788Z "
            transform="rotate(36, 400, 400)"
            opacity="0.48"
          ></path>
          <path
            d="M385.6047533602368 192.38170320466764C394.5125319809223 187.23972110494424 405.4874680190777 187.23972110494424 414.3952466397632 192.3831427293316L572.6047653094163 283.7253012326208C581.5125439301016 288.8672833323442 587.0000119491793 298.3724646885798 587.0000119491793 308.6578684126906V491.34218541926884C587.0000119491793 501.62758914337974 581.5125439301016 511.1327704996154 572.6047653094163 516.2761921240026L414.3952466397632 607.6183506272919C405.4874680190777 612.7603327270152 394.5125319809223 612.7603327270152 385.6047533602368 607.6169111026279L227.39523469058383 516.2747525993387C218.48745606989837 511.1327704996154 212.99998805082066 501.62758914337974 212.99998805082066 491.34218541926884V308.6578684126906C212.99998805082066 298.3724646885798 218.48745606989837 288.8672833323442 227.39523469058383 283.7238617079568L385.6047533602368 192.38170320466764Z "
            transform="rotate(34, 400, 400)"
            opacity="0.51"
          ></path>
          <path
            d="M386.45153257434055 204.59448315747215C394.83532421733855 199.75497059302657 405.16467578266145 199.75497059302657 413.54846742565945 204.59583800421473L562.451543820627 290.56492836025154C570.835335463625 295.4044409246971 576.0000112462865 304.35049396586004 576.0000112462865 314.0308739414938V485.96905465356735C576.0000112462865 495.6494346292011 570.835335463625 504.59548767036404 562.451543820627 509.43635508155216L413.54846742565945 595.405445437589C405.16467578266145 600.2449580020345 394.83532421733855 600.2449580020345 386.45153257434055 595.4040905908464L237.54845617937303 509.43500023480965C229.16466453637497 504.59548767036404 223.99998875371358 495.6494346292011 223.99998875371358 485.96905465356735V314.0308739414938C223.99998875371358 304.35049396586004 229.16466453637497 295.4044409246971 237.54845617937303 290.563573513509L386.45153257434055 204.59448315747215Z "
            transform="rotate(32, 400, 400)"
            opacity="0.54"
          ></path>
          <path
            d="M387.29831178844427 216.80735466301098C395.15811645375493 212.27031163384328 404.84188354624507 212.27031163384328 412.70168821155573 216.80862483183216L552.2983223318378 297.4046470406167C560.1581269971484 301.9416900697844 565.0000105433935 310.3286147958746 565.0000105433935 319.40397102303126V480.59601544060024C565.0000105433935 489.67137166775683 560.1581269971484 498.05829639384706 552.2983223318378 502.596609591836L412.70168821155573 583.1926318006206C404.84188354624507 587.7296748297882 395.15811645375493 587.7296748297882 387.29831178844427 583.1913616317994L247.70167766816218 502.5953394230148C239.84187300285151 498.05829639384706 234.99998945660644 489.67137166775683 234.99998945660644 480.59601544060024V319.40397102303126C234.99998945660644 310.3286147958746 239.84187300285151 301.9416900697844 247.70167766816218 297.40337687179556L387.29831178844427 216.80735466301098Z "
            transform="rotate(30, 400, 400)"
            opacity="0.57"
          ></path>
          <path
            d="M388.145091002548 229.0201956509717C395.4809086901713 224.78562215708183 404.5190913098287 224.78562215708183 411.854908997452 229.02138114187147L542.1451008430486 304.2443352034037C549.4809185306719 308.47890869729355 554.0000098405006 316.3067051083111 554.0000098405006 324.7770375869906V475.2229457100551C554.0000098405006 483.69327818873455 549.4809185306719 491.5210745997522 542.1451008430486 495.7568335845417L411.854908997452 570.979787646074C404.5190913098287 575.2143611399638 395.4809086901713 575.2143611399638 388.145091002548 570.9786021551743L257.8548991569514 495.7556480936421C250.5190814693281 491.5210745997522 245.99999015949936 483.69327818873455 245.99999015949936 475.2229457100551V324.7770375869906C245.99999015949936 316.3067051083111 250.5190814693281 308.47890869729355 257.8548991569514 304.24314971250396L388.145091002548 229.0201956509717Z "
            transform="rotate(28, 400, 400)"
            opacity="0.60"
          ></path>
          <path
            d="M388.9918702166517 241.23303663893245C395.8037009265876 237.30093268032041 404.1962990734124 237.30093268032041 411.0081297833483 241.23413745191078L531.9918793542595 311.0840233661907C538.8037100641953 315.0161273248027 543.0000091376078 322.2847954207476 543.0000091376078 330.15010415095V469.8498759795099C543.0000091376078 477.7151847097123 538.8037100641953 484.98385280565714 531.9918793542595 488.9170575772475L411.0081297833483 558.7669434915274C404.1962990734124 562.6990474501395 395.8037009265876 562.6990474501395 388.9918702166517 558.765842678549L268.0081206457406 488.91595676426914C261.19628993580466 484.98385280565714 256.9999908623923 477.7151847097123 256.9999908623923 469.8498759795099V330.15010415095C256.9999908623923 322.2847954207476 261.19628993580466 315.0161273248027 268.0081206457406 311.08292255321237L388.9918702166517 241.23303663893245Z "
            transform="rotate(26, 400, 400)"
            opacity="0.63"
          ></path>
          <path
            d="M389.8386494307554 253.4458928856822C396.12649316300394 249.81625846234806 403.87350683699606 249.81625846234806 410.1613505692446 253.44690902073916L521.8386578654703 317.9237267877668C528.1265015977187 321.55336121110093 532.0000084347148 328.26290099197314 532.0000084347148 335.52318597369845V464.4768215077537C532.0000084347148 471.73710648947895 528.1265015977187 478.44664627035115 521.8386578654703 482.0772968287422L410.1613505692446 546.5541145957699C403.87350683699606 550.183749019104 396.12649316300394 550.183749019104 389.8386494307554 546.553098460713L278.1613421345298 482.07628069368536C271.8734984022812 478.44664627035115 267.9999915652852 471.73710648947895 267.9999915652852 464.4768215077537V335.52318597369845C267.9999915652852 328.26290099197314 271.8734984022812 321.55336121110093 278.1613421345298 317.92271065270984L389.8386494307554 253.4458928856822Z "
            transform="rotate(24, 400, 400)"
            opacity="0.66"
          ></path>
          <path
            d="M390.68542864485914 265.658733873643C396.4492853994203 262.3315689855866 403.5507146005797 262.3315689855866 409.31457135514086 265.65966533077847L511.6854363766811 324.7634149505538C517.4492931312423 328.0905798386101 521.0000077318219 334.2409913044096 521.0000077318219 340.8962525376578V459.10375177720846C521.0000077318219 465.75901301045667 517.4492931312423 471.90942447625616 511.6854363766811 475.237520821448L409.31457135514086 534.3412704412233C403.5507146005797 537.6684353292796 396.4492853994203 537.6684353292796 390.68542864485914 534.3403389840878L288.3145636233189 475.2365893643125C282.5507068687578 471.90942447625616 278.99999226817806 465.75901301045667 278.99999226817806 459.10375177720846V340.8962525376578C278.99999226817806 334.2409913044096 282.5507068687578 328.0905798386101 288.3145636233189 324.7624834934183L390.68542864485914 265.658733873643Z "
            transform="rotate(22, 400, 400)"
            opacity="0.69"
          ></path>
          <path
            d="M391.53220785896286 277.8715824909982C396.77207763583664 274.8468871382197 403.22792236416336 274.8468871382197 408.46779214103714 277.8724292702123L501.5322148878919 331.60311074273534C506.77208466476566 334.6278060955138 510.000007028929 340.2190892462406 510.000007028929 346.2693267310117V453.73068967605775C510.000007028929 459.7809271608288 506.77208466476566 465.37221031155565 501.5322148878919 468.39775244354826L408.46779214103714 522.1284339160712C403.22792236416336 525.1531292688497 396.77207763583664 525.1531292688497 391.53220785896286 522.1275871368572L298.4677851121081 468.3969056643341C293.22791533523434 465.37221031155565 289.999992971071 459.7809271608288 289.999992971071 453.73068967605775V346.2693267310117C289.999992971071 340.2190892462406 293.22791533523434 334.6278060955138 298.4677851121081 331.60226396352124L391.53220785896286 277.8715824909982Z "
            transform="rotate(20, 400, 400)"
            opacity="0.72"
          ></path>
          <path
            d="M392.3789870730666 290.08443110835344C397.09486987225296 287.36220529085284 402.90513012774704 287.36220529085284 407.6210129269334 290.08519320964615L491.3789933991027 338.4428065349169C496.09487619828906 341.1650323524175 499.0000063260361 346.19718718807167 499.0000063260361 351.64240092436563V448.35762757490704C499.0000063260361 453.802841311201 496.09487619828906 458.83499614685513 491.3789933991027 461.5579840656485L407.6210129269334 509.9155973909192C402.90513012774704 512.6378232084198 397.09486987225296 512.6378232084198 392.3789870730666 509.9148352896265L308.6210066008973 461.5572219643558C303.90512380171094 458.83499614685513 300.9999936739639 453.802841311201 300.9999936739639 448.35762757490704V351.64240092436563C300.9999936739639 346.19718718807167 303.90512380171094 341.1650323524175 308.6210066008973 338.4420444336242L392.3789870730666 290.08443110835344Z "
            transform="rotate(18, 400, 400)"
            opacity="0.75"
          ></path>
          <path
            d="M393.22576628717025 302.2972492081306C397.4176621086693 299.87749292590786 402.5823378913307 299.87749292590786 406.77423371282975 302.2979266315019L481.2257719103135 345.2824718095203C485.4176677318125 347.70222809174305 488.00000562314324 352.1752546123245 488.00000562314324 357.0154446001414V442.9845349561782C488.00000562314324 447.8247249439951 485.4176677318125 452.29775146457655 481.2257719103135 454.7181851701706L406.77423371282975 497.70273034818905C402.5823378913307 500.1224866304118 397.4176621086693 500.1224866304118 393.22576628717025 497.70205292481774L318.7742280896865 454.71750774679936C314.5823322681875 452.29775146457655 311.99999437685676 447.8247249439951 311.99999437685676 442.9845349561782V357.0154446001414C311.99999437685676 352.1752546123245 314.5823322681875 347.70222809174305 318.7742280896865 345.28179438614904L393.22576628717025 302.2972492081306Z "
            transform="rotate(16, 400, 400)"
            opacity="0.79"
          ></path>
          <path
            d="M394.072545501274 314.5100901960913C397.74045434508565 312.3928034491464 402.25954565491435 312.3928034491464 405.927454498726 314.5106829415412L471.0725504215243 352.1221599723073C474.74045926533597 354.2394467192522 477.0000049202503 358.15334492476103 477.0000049202503 362.3885111641008V437.611465225633C477.0000049202503 441.84663146497275 474.74045926533597 445.76052967048156 471.0725504215243 447.8784091628763L405.927454498726 485.48988619364246C402.25954565491435 487.60717294058736 397.74045434508565 487.60717294058736 394.072545501274 485.4892934481926L328.9274495784757 447.8778164174265C325.25954073466403 445.76052967048156 322.9999950797497 441.84663146497275 322.9999950797497 437.611465225633V362.3885111641008C322.9999950797497 358.15334492476103 325.25954073466403 354.2394467192522 328.9274495784757 352.12156722685745L394.072545501274 314.5100901960913Z "
            transform="rotate(14, 400, 400)"
            opacity="0.82"
          ></path>
          <path
            d="M394.9193247153777 326.72294644284113C398.06324658150197 324.90812923117403 401.93675341849803 324.90812923117403 405.0806752846223 326.7234545103696L460.91932893273514 358.9618633938834C464.06325079885937 360.7766806055505 466.0000042173574 364.13145049598654 466.0000042173574 367.7615929868492V432.23841075387685C466.0000042173574 435.8685532447395 464.06325079885937 439.2233231351756 460.91932893273514 441.0386484143711L405.0806752846223 473.27705729788494C401.93675341849803 475.091874509552 398.06324658150197 475.091874509552 394.9193247153777 473.2765492303565L339.08067106726486 441.0381403468427C335.93674920114063 439.2233231351756 333.9999957826426 435.8685532447395 333.9999957826426 432.23841075387685V367.7615929868492C333.9999957826426 364.13145049598654 335.93674920114063 360.7766806055505 339.08067106726486 358.9613553263549L394.9193247153777 326.72294644284113Z "
            transform="rotate(12, 400, 400)"
            opacity="0.85"
          ></path>
          <path
            d="M395.7661039294814 338.93578743080184C398.3860388179183 337.4234397544126 401.6139611820817 337.4234397544126 404.2338960705186 338.93621082040886L450.76610744394594 365.8015515566704C453.38604233238283 367.31389923305966 455.00000351446454 370.10954080842305 455.00000351446454 373.1346595508086V426.8653410233316C455.00000351446454 429.89045976571714 453.38604233238283 432.68610134108053 450.76610744394594 434.19887240707686L404.2338960705186 461.06421314333835C401.6139611820817 462.5765608197276 398.3860388179183 462.5765608197276 395.7661039294814 461.0637897537313L349.23389255605406 434.1984490174698C346.61395766761717 432.68610134108053 344.99999648553546 429.89045976571714 344.99999648553546 426.8653410233316V373.1346595508086C344.99999648553546 370.10954080842305 346.61395766761717 367.31389923305966 349.23389255605406 365.8011281670633L395.7661039294814 338.93578743080184Z "
            transform="rotate(10, 400, 400)"
            opacity="0.88"
          ></path>
          <path
            d="M396.6128831435851 351.1486360481571C398.70883105433467 349.93875790704567 401.29116894566533 349.93875790704567 403.3871168564149 351.14897475984276L440.61288595515674 372.64124734885195C442.7088338659063 373.85112548996335 444.0000028115716 376.0876387502541 444.0000028115716 378.5077337441625V421.4922789221809C444.0000028115716 423.91237391608934 442.7088338659063 426.1488871763801 440.61288595515674 427.3591040291771L403.3871168564149 448.85137661818635C401.29116894566533 450.0612547592977 398.70883105433467 450.0612547592977 396.6128831435851 448.85103790650066L359.38711404484326 427.3587653174915C357.2911661340937 426.1488871763801 355.9999971884284 423.91237391608934 355.9999971884284 421.4922789221809V378.5077337441625C355.9999971884284 376.0876387502541 357.2911661340937 373.85112548996335 359.38711404484326 372.6409086371663L396.6128831435851 351.1486360481571Z "
            transform="rotate(8, 400, 400)"
            opacity="0.91"
          ></path>
          <path
            d="M397.45966235768884 363.3614694067233C399.031623290751 362.45406080088975 400.968376709249 362.45406080088975 402.54033764231116 363.3617234404875L430.45966446636754 379.48092788224443C432.0316253994297 380.388336488078 433.0000021086787 382.065721433296 433.0000021086787 383.88079267872735V416.11920156224113C433.0000021086787 417.93427280767247 432.0316253994297 419.61165775289055 430.45966446636754 420.5193203924883L402.54033764231116 436.63852483424523C400.968376709249 437.5459334400787 399.031623290751 437.5459334400787 397.45966235768884 436.638270800481L369.54033553363246 420.51906635872405C367.9683746005703 419.61165775289055 366.9999978913213 417.93427280767247 366.9999978913213 416.11920156224113V383.88079267872735C366.9999978913213 382.065721433296 367.9683746005703 380.388336488078 369.54033553363246 379.4806738484802L397.45966235768884 363.3614694067233Z "
            transform="rotate(6, 400, 400)"
            opacity="0.94"
          ></path>
          <path
            d="M398.30644157179256 375.574310394684C399.3544155271673 374.96937132412836 400.6455844728327 374.96937132412836 401.69355842820744 375.57447975052685L420.3064429775784 386.32061604503144C421.35441693295314 386.92555511558714 422.0000014057858 388.0438117457325 422.0000014057858 389.2538592426867V410.74613183169595C422.0000014057858 411.95617932865014 421.35441693295314 413.0744359587955 420.3064429775784 413.67954438519405L401.69355842820744 424.42568067969864C400.6455844728327 425.03061975025435 399.3544155271673 425.03061975025435 398.30644157179256 424.4255113238558L379.6935570224216 413.6793750293512C378.64558306704686 413.0744359587955 377.9999985942142 411.95617932865014 377.9999985942142 410.74613183169595V389.2538592426867C377.9999985942142 388.0438117457325 378.64558306704686 386.92555511558714 379.6935570224216 386.3204466891886L398.30644157179256 375.574310394684Z "
            transform="rotate(4, 400, 400)"
            opacity="0.97"
          ></path>
          <path
            d="M399.1532207858963 387.78715138264477C399.6772077635837 387.4846818473669 400.3227922364163 387.4846818473669 400.8467792141037 387.78723606056616L410.1532214887892 393.16030420781846C410.67720846647654 393.4627737430963 411.0000007028929 394.02190205816896 411.0000007028929 394.6269258066461V405.3730621011507C411.0000007028929 405.9780858496278 410.67720846647654 406.5372141647005 410.1532214887892 406.83976837789976L400.8467792141037 412.21283652515206C400.3227922364163 412.5153060604299 399.6772077635837 412.5153060604299 399.1532207858963 412.21275184723066L389.8467785112108 406.8396836999783C389.32279153352346 406.5372141647005 388.9999992971071 405.9780858496278 388.9999992971071 405.3730621011507V394.6269258066461C388.9999992971071 394.02190205816896 389.32279153352346 393.4627737430963 389.8467785112108 393.16021952989706L399.1532207858963 387.78715138264477Z "
            transform="rotate(2, 400, 400)"
            opacity="1.00"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default ImageHero;
